<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <div class="mr-3">
      <v-icon large v-if="!drawer" @click="setDrawer(!drawer)">
        mdi-menu
      </v-icon>
    </div>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-medium h6"
      v-text="$route.name"
    />

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-medium h6"
      v-text="$route.icon"
    />

    <v-spacer />
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2 userAvatar"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-text>{{ getUserDetails.nickname }}</v-text>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div class="username-section">
          <v-text>
            {{ getUserDetails.firstname }} {{ getUserDetails.lastname }}
          </v-text>
          <v-text>Logged in</v-text>
        </div>
        <div class="buttonSection">
          <v-btn
            background="transparent"
            @click="$router.push('/app/pages/profile')"
            color="black"
            class="menu-button"
            style="padding: 10px 15px !important"
            text
          >
            <v-icon class="mr-1">mdi-account-outline</v-icon>
            <v-text>Profile</v-text>
          </v-btn>
        </div>
        <div class="buttonSection">
          <v-btn
            background="transparent"
            text
            @click="doLogout()"
            style="padding: 10px 15px !important"
            color="black"
          >
            <v-icon class="mr-1"> mdi-logout</v-icon>
            <v-text>Log out</v-text>
          </v-btn>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";
import "./AppBar.scss";
// Utilities
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  mounted: () => {},
  data: () => ({}),

  computed: {
    ...mapState(["drawer"]),
    ...mapGetters({
      getUserDetails: "getUserData",
    }),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    doLogout() {
      localStorage.removeItem("user");
      this.$router.push("/");
    },
  },
};
</script>
